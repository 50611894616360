form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

form * { font-size: 0.85rem; }

/* buttons and input */
button,
input:not([ type = "checkbox" ], [ type = "radio" ]),
textarea,
.input-field .options > * {
    border: none;
    border-radius: var(--corner-rounding);
    padding: 0.5rem 1rem;
}

input:not([ type = "checkbox" ], [ type = "radio" ]),
textarea,
.input-field .options > * { background: var(--theme-color-10); }

/* button */
button {
    background-color: var(--theme-button);
    color: var(--theme-color-10);
    cursor: pointer;
    width: fit-content;
}

/* text based inputs */
input:not([ type = "checkbox" ], [ type = "radio" ]) { cursor: text; }
textarea {
    height: 7.5rem;
    resize: none;
}

/* click based inputs */
input[ type = "checkbox" ], input[ type = "radio" ] { display: none; }

/* divider */
.divider { height: 2rem; }

/* input fields */
.input-field {
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
    gap: 0.33rem;
}
.input-field .options {
    align-items: center;
    display: flex;
    gap: 1rem;
}
.input-field .options > * {
    cursor: pointer;
    overflow: hidden;
    position: relative;
}
.input-field .options > .selected::before {
    background: rgba(0, 0, 0, 0.1666);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@media screen and (orientation: landscape) and (min-width: 1081px) {
    /* input fields */
    .input-field { width: 25rem; }
}