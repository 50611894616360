:root { --LAYOUT-INLINE-PADDING: var(--padding-size); }

#page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    overflow-y: auto;
    padding: var(--padding-size) var(--LAYOUT-INLINE-PADDING);
}

#page > #content { flex-grow: 1; }

/* landscape orientation, all width sizes */
@media screen and (orientation: landscape) {
    :root { --LAYOUT-INLINE-PADDING: calc(var(--padding-size) * 7.5); }
    #page { padding: var(--padding-size) var(--LAYOUT-INLINE-PADDING); }
}

/* landscape orientation, small width size */
@media screen and (orientation: landscape) and (max-width: 1079px) {
    :root { --LAYOUT-INLINE-PADDING: calc(var(--padding-size) * 2); }
}