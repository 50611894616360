.card {
    background-color: var(--theme-color-10);
    border-radius: var(--corner-rounding);
    display: flex;
}

/* Item Card: Generic/ General Styles */
.card.item { gap: 1.5rem; }
.card.item > .image-container img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
}
.card.item > .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card.item > .text-container > .name {
    /* font-size: 1.125rem; */
    font-weight: 500;
}
.card.item > .text-container > .price-n-inventory {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
/* Item Card: Grid Layout Styles */
.card.item.grid {
    flex-direction: column;
    padding: 1.5rem;
}
.card.item.grid > .text-container { text-align: center; }
/* Item Card: List Layout Styles */
.card.item.list {
    height: 4.5rem;
    padding: 0.5rem;
}
.card.item.list > .image-container {
    flex: 1 0 0;
    height: 100%;
}
.card.item.list > .text-container { flex: 3 0 0; }


/* Session Card */
.card.session {
    background: var(--theme-color-10);
    display: flex;
    font-size: 0.85em;
    gap: 0.5em;
    padding: 0.5em 1em;
}
.card.session > .datetime { flex: 1.5 0 0; }
.card.session > .location { flex: 1 0 0; }
.card.session > .client { flex: 1 0 0; }
.card.session > .duration { flex: 1 0 0; }



@media screen and (orientation: landscape) and (max-width: 1080px) {
    .card.item.grid { padding: 0.5rem; }
    .card.item.grid > .text-container { height: 40%; }
}

@media screen and (orientation: portrait) {
    .card.item.grid { padding: 0.5rem; }
    .card.item.grid > .image-container { padding: 1rem; }
    .card.item.grid > .text-container { height: 40%; }
}