[ data-page-name = "items" ] main {
    --BANNER-SIZE: 30vh;
    --PAGE-SPACING: 1rem;

    display: flex;
    flex-direction: column;
    font-size: 0.85em;
    gap: var(--PAGE-SPACING);
    margin-top: calc(var(--BANNER-SIZE) + var(--PAGE-SPACING));
    height: 100%;
    overflow-y: visible;
    position: relative;
    width: 100%;
}
[ data-page-name = "items" ] main::after,
[ data-page-name = "items" ] main::before {
    border-radius: var(--corner-rounding);
    bottom: calc(100% + var(--PAGE-SPACING));
    content: "";
    height: calc(var(--BANNER-SIZE) / 3);
    left: 0;
    position: absolute;
    width: 100%;
}
[ data-page-name = "items" ] main::after {
    background: var(--theme-color-30);
    opacity: 0.875;
}
[ data-page-name = "items" ] main::before { background: url("../images/533b0aa7811a79497d6b43623f6109b7.jpg"); }

[ data-page-name = "items" ] main > * { flex-shrink: 0; }

/* filter panel */
[ data-page-name = "items" ] main > #head {
    background: var(--theme-color-10);
    border-radius: var(--corner-rounding);
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: var(--layer-nav-tab);
}

/* content/ grid */
[ data-page-name = "items" ] main > #content {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
}
[ data-page-name = "items" ] main > #content .card { aspect-ratio: 1/1; }

/* add new cta button */
[ data-page-name = "items" ] main > #add-new {
    align-items: center;
    background: var(--theme-button);
    border-radius: 2rem;
    color: var(--theme-color-10);
    display: flex;
    right: var(--LAYOUT-INLINE-PADDING);
    padding-inline-end: 1rem;
    position: fixed;
    bottom: 2rem;
}
[ data-page-name = "items" ] main > #add-new > :nth-child(1) {
    align-items: center;
    display: flex;
    height: 2.25rem;
    justify-content: center;
    width: 2.25rem;
}
[ data-page-name = "items" ] main > #add-new > :nth-child(1) img { height: 1rem; }


[ data-page-name = "items" ] main > #intro {
    display: flex;
    flex-direction: column;
    height: var(--BANNER-SIZE);
    margin-top: calc(-1 * var(--BANNER-SIZE));;
    width: 100%;
    position: relative;
    z-index: var(--layer-banner);
}
[ data-page-name = "items" ] main > #intro > .title {
    font-size: 1.125em;
    font-weight: 500;
}
[ data-page-name = "items" ] main > #intro > .summary {
    display: grid;
    flex-grow: 1;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    padding: 7.5% 0 7.5% 7.5%;
}
[ data-page-name = "items" ] main > #intro > .summary > .image {
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-end: 5;
    grid-row-start: 1;
}
[ data-page-name = "items" ] main > #intro > .summary > .image > img { height: 100%; }

[ data-page-name = "items" ] main > #intro > .summary > .text {
    font-weight: 500;
    grid-column-end: 4;
    grid-column-start: 2;
    grid-row-end: 4;
    grid-row-start: 2;
}

/* [ data-page-name = "items" ] #sorting {
    align-items: center;
    background: #FFFFFF;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    left: 0;
    padding-inline-start: 0;
    position: sticky;
}
[ data-page-name = "items" ] #sorting > .options { position: relative; }
[ data-page-name = "items" ] #sorting > .options > .alts {
    background: #ffffff;
    border-radius: var(--corner-rounding);
    padding: 0.5rem;
    position: absolute;
    right: 0;
    top: calc(100% + 1rem);
    width: max-content;
    z-index: 150;
} */

@media screen and (orientation: landscape) {
    [ data-page-name = "items" ] main {
        --BANNER-SIZE: 60vh;

        display: inline-block;
        position: relative;
        width: 100%;
    }

    [ data-page-name = "items" ] main > * { float: left; }

    /* filter panel */
    [ data-page-name = "items" ] main > #head {
        height: fit-content;
        max-height: 65vh;
        overflow-y: auto;
        width: 20%;
    }

    /* content/ grid */
    [ data-page-name = "items" ] main > #content {
        grid-template-columns: repeat(4, 1fr);
        padding: 0 1rem;
        width: 80%;
    }
    
    /* add new cta button */
    [ data-page-name = "items" ] main > #add-new {
        color: var(--theme-color-10);
        padding-inline-end: 1rem;
        position: fixed;
        bottom: 2rem;
    }

    /* [ data-page-name = "items" ] #sorting {
        left: initial;
        padding-inline: 0;
        position: initial;
    } */

    @media screen and (max-width: 900px) {
        [ data-page-name = "items" ] main > #content { grid-template-columns: repeat(3, 1fr); }
    }
}


@media screen and (orientation: portrait) {
    [ data-page-name = "items" ] main > #intro > .summary > .image {
        grid-column-end: 3;
        grid-column-start: 1;
        grid-row-end: 5;
        grid-row-start: 1;
    }
    [ data-page-name = "items" ] main > #intro > .summary > .image > img {
        height: auto;
        width: 100%;
    }

    [ data-page-name = "items" ] main > #intro > .summary > .text {
        /* background-color: green; */
        font-size: 0.75rem;
        grid-column-end: 4;
        grid-column-start: 3;
        grid-row-end: 4;
        grid-row-start: 2;
        /* padding: 0.25rem; */
    }
}