/* dark theme related variables */
@media (prefers-color-scheme: dark) {
    :root {
        --theme-alt: #eddbda;
        --theme-background: #f1f1f1;
        --theme-button: #3c3c3c;
        --theme-color-10: #ffffff;
        /* --theme-color-10: #eddbda; */
        --theme-color-30: #9c0c01;
        --theme-color-60: #ffc000;
        --theme-text-default: #383838;
        --theme-text-bold: #000000;
        --theme-text-light: #636363;
        background: var(--theme-background);
        color-scheme: dark;
        color: var(--theme-text-default);
    }

    img, video { filter: brightness(.8) contrast(1.2); }
}

/* light theme related variables */
@media (prefers-color-scheme: light) {
    :root {
        --theme-alt: #eddbda;
        --theme-background: #f1f1f1;
        --theme-button: #3c3c3c;
        --theme-color-10: #eddbda;
        --theme-color-30: #9c0c01;
        --theme-color-60: #ffc000;
        --theme-text-default: #383838;
        --theme-text-bold: #000000;
        --theme-text-light: #636363;
        background: var(--theme-background);
        color-scheme: light;
        color: var(--theme-text-default);
    }

    img, video { filter: initial; }
}