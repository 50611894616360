[ data-page-name = "dashboard" ] > #content {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--padding-size));
}


/* generic/ shared elements */
[ data-page-name = "dashboard" ] .head {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
[ data-page-name = "dashboard" ] .head > .title {
    font-size: 1.25rem;
    font-weight: 500;
}
[ data-page-name = "dashboard" ] .head > .ctas {
    align-items: center;
    display: flex;
    font-size: 0.85em;
    gap: 1rem;
}
[ data-page-name = "dashboard" ] .head > .ctas > :first-child {
    align-items: center;
    background: var(--theme-button);
    border-radius: 50%;
    color: var(--theme-color-10);
    display: flex;
    height: 1.5rem;
    justify-content: center;
    width: 1.5rem;
}
[ data-page-name = "dashboard" ] .head > .ctas > :first-child img { height: 1rem; }


/* targets both main and aside elements */
[ data-page-name = "dashboard" ] > #content > * {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
[ data-page-name = "dashboard" ] > #content > * > .head { height: fit-content; }
[ data-page-name = "dashboard" ] > #content > * > .content { flex-grow: 1; }


/* aside styles */
[ data-page-name = "dashboard" ]  aside { overflow-y: auto; }
[ data-page-name = "dashboard" ]  aside > .content {
    display: flex;
    flex-direction: column;
}

[ data-page-name = "dashboard" ]  aside > .other {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    height: 5rem;
}
[ data-page-name = "dashboard" ]  aside > .other > * {
    align-items: center;
    background: var(--theme-button);
    border-radius: var(--corner-rounding);
    color: var(--theme-color-10);
    cursor: pointer;
    display: flex;
    font-size: 0.85em;
    justify-content: center;
    padding: 0.3em 0em;
}
[ data-page-name = "dashboard" ]  aside > .other > :nth-child(1) {
    font-weight: 500;
    grid-row-end: 3;
    grid-row-start: 1;
}
    

/* main styles */
[ data-page-name = "dashboard" ]  main > .content {
    display: grid;
    font-size: 0.85em;
    gap: 1rem;
}
[ data-page-name = "dashboard" ]  main > .content > * {
    background-color: var(--theme-color-10);
    border-radius: var(--corner-rounding);
    color: var(--theme-text-default);
    overflow: hidden;
    padding: 0.5em;
    position: relative;
}
[ data-page-name = "dashboard" ]  main > .content > ::after {
    clip-path: polygon(0 100%, 9% 89%, 25% 82%, 37% 85%, 52% 86%, 66% 78%, 83% 70%, 95% 38%, 100% 0, 100% 100%);
    content: "";
    height: calc(100% - 2.5vh);
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
}
[ data-page-name = "dashboard" ]  main > .content > :nth-child(1):after { background: var(--theme-color-30); }
[ data-page-name = "dashboard" ]  main > .content > :nth-child(2):after { background: var(--theme-color-60); }
[ data-page-name = "dashboard" ]  main > .content > :nth-child(3):after { background: var(--theme-alt); }
[ data-page-name = "dashboard" ]  main > .content > :nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
}


/*  */
@media screen and (orientation: landscape) {
    [ data-page-name = "dashboard" ] > #content { flex-direction: row; }

    [ data-page-name = "dashboard" ]  aside { flex: 1 0 0; }
    [ data-page-name = "dashboard" ]  aside > .content { gap: 0.875rem; }

    [ data-page-name = "dashboard" ]  main {
        flex: 2 0 0;
        height: 100%;
    }
    [ data-page-name = "dashboard" ]  main > .content {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1.5fr 1fr;
    }

    @media screen and (max-width: 1080px) {
        [ data-page-name = "dashboard" ]  aside > .content { gap: 0.5rem; }

        [ data-page-name = "dashboard" ]  main { min-height: 182.5vh !important; }
    }
}

@media screen and (orientation: portrait) {
    [ data-page-name = "dashboard" ]  aside > .content { gap: 1rem; }

    [ data-page-name = "dashboard" ]  main > .content {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 40vh 20vh;
    }
}