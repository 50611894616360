:root {
    --corner-rounding: 0.33rem;
    --padding-size: 1rem;
    --theme-neutral-dark: #3c3c3c;
    --theme-neutral-light: #eddbda;
    --theme-orange: #ffc000;
    --theme-orange-40: #ffbf0066;
    --theme-red: #9c0c01;
    --theme-red-40: #9d0b0166;

    
    --layer-banner: 5;
    --layer-nav-head: 19;
    --layer-nav-body: 17;
    --layer-nav-dropdown: 18;
    --layer-nav-tab: 15;
    --layer-underlay: -1;
    --layer-window: 10;
}

@media screen and (orientation: landscape) {
    :root {
        --padding-size: 1rem;
    }
}