.nav {
    align-items: center;
    display: flex;
    font-size: 0.85em;
    justify-content: space-between;
}

.bar { display: flex; }
.bar > .head { height: 3rem; }
.bar > .head > * { cursor: pointer; }
.bar > .head > :not(.back) { height: 100%; }
.bar > .head > .brand > img { height: 100%; }
.bar > .head > .back > img { height: 1.5em; }

.bar > .body { display: flex; }
.bar > .body > .links {
    display: flex;
    gap: 1rem;
}
.bar > .body > .links > * { cursor: pointer; }
.bar > .body > .links > .active { font-weight: 750; }


.bar > .body > .account-manager {
    color: var(--theme-color-10);
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;
}
.bar > .body > .account-manager > .controller {
    align-items: center;
    background: var(--theme-text-default);
    border-radius: 2rem;
    display: flex;
    gap: 1rem;
    height: 2.25rem;
    padding: 0.25rem 0.25rem 0.25rem 0.75rem;
}
.bar > .body > .account-manager > .controller > .picture {
    aspect-ratio: 1/1;
    border-radius: 2rem;
    height: 100%;
    overflow: hidden;
}
.bar > .body > .account-manager > .controller > .picture > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.bar > .body > .account-manager > .options > * { display: block; }

.tabs {
    gap: 1rem;
    overflow-x: auto;
    white-space: nowrap;
}
.tabs::-webkit-scrollbar { height: 0; }
.tabs > * {
    cursor: pointer;
    flex: 1 0 0;
    padding: 1rem 1.25rem;
}
.tabs > .active {
    background: var(--theme-button);
    color: var(--theme-alt);
    font-weight: 750;
}

@media screen and (orientation: landscape) {
    .nav { flex-direction: row; }
    
    .bar { position: relative; }

    .bar > .head > .menu { display: none; }

    .bar > .body > .links {
        align-items: center;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .bar > .body > .account-manager { align-items: flex-end; }
    .bar > .body > .account-manager > .controller {
        align-items: center;
        background: var(--theme-text-default);
        border-radius: 2rem;
        display: flex;
        gap: 1rem;
        height: 2.25rem;
        padding: 0.25rem 0.25rem 0.25rem 0.75rem;
    }
    .bar > .body > .account-manager > .options {
        background-color: var(--theme-text-default);
        border-radius: var(--corner-rounding);
        display: none;
        min-width: 100%;
        padding: var(--padding-size);
        position: absolute;
        top: calc(100% + 1rem);
        width: max-content;
        z-index: 15;
    }
    .bar > .body > .account-manager.drop > .options { display: initial; }
    
    .tabs.dynamic {
        flex-direction: column;
        gap: 0.5rem;
        overflow-x: initial;
        white-space: initial;
    }
    .tabs.dynamic > * {
        width: 100%;
        padding: 0.5rem 2.25rem;
    }

    @media screen and (max-width: 1081px) {
        .tabs.dynamic > * { padding: 0.5rem 1.25rem; }
    }
}

@media screen and (orientation: portrait) {
    .bar { flex-direction: column; }

    .bar > * { width: 100%; }
    .bar > .head {
        align-items: center;
        background: var(--theme-background);
        display: flex;
        justify-content: space-between;
        z-index: var(--layer-nav-head);
    }
    .bar > .head > .menu {
        align-items: center;
        display: flex;
        font-weight: 750;
    }

    .bar.closed > :not(.head) { display: none; }
    .bar > .body {
        background: var(--theme-background);
        flex-direction: column;
        height: 100%;
        left: 0;
        padding: 7.5rem var(--padding-size) var(--padding-size);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: var(--layer-nav-body);
    }
    .bar > .body > .links {
        flex-direction: column;
        flex-grow: 1;
    }
    .bar > .body > .account-manager {
        background: var(--theme-text-default);
        border-radius: var(--corner-rounding);
        padding: 0.5rem;
        position: relative;
    }
    .bar > .body > .account-manager > .controller {
        align-items: center;
        background: var(--theme-text-default);
        border-radius: 2rem;
        display: flex;
        gap: 1rem;
        height: 2.25rem;
        padding: 0.25rem 0.25rem 0.25rem 5rem;
    }
    .bar > .body > .account-manager > .controller > .picture {
        border-radius: 0.5rem;
        height: calc(100% - 1rem);
        left: 0.5rem;
        position: absolute;
        top: 0.5rem;
    }
    
    .bar > .body > .account-manager > .options { padding-left: 5rem; }
}