[ data-page-name = "profile" ] > #content { position: relative; }

[ data-page-name = "profile" ] aside {
    background: var(--theme-alt);
    border-radius: var(--corner-rounding);
    padding: 1rem;
    height: fit-content;
    max-height: calc(85% - (2 * 10vh));
    min-height: calc(75% - (2 * 10vh));
    overflow-y: auto;
    padding: 2rem;
    position: fixed;
    right: 5vw;
    top: 20vh;
    width: calc(100% - 10vw);
    z-index: var(--layer-window);
}
/* [ data-page-name = "profile" ] aside::before {
    background: rgba(0, 0, 0, 0.33);
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
} */
[ data-page-name = "profile" ] aside > .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

[ data-page-name = "profile" ] main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
}

/* section */
[ data-page-name = "profile" ] section {
    background: var(--theme-color-10);
    border-radius: var(--corner-rounding);
    padding: 1rem;
}
[ data-page-name = "profile" ] section > .head {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
[ data-page-name = "profile" ] section > .head > .title {
    font-size: 1.125rem;
    font-weight: 450;
}
[ data-page-name = "profile" ] section:first-child > .head > .title {
    font-size: 1.25rem;
    font-weight: 500;
}
[ data-page-name = "profile" ] section > .head > .cta {
    background: var(--theme-button);
    border-radius: 1rem;
    color: var(--theme-color-10);
    cursor: pointer;
    font-size: 0.75em;
    padding: 0.125rem 1rem;
}
[ data-page-name = "profile" ] section > .content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1.5rem 1rem;
}

/* section fields */
[ data-page-name = "profile" ] .field {
    display: flex;
    flex-direction: column;
    font-size: 0.825em;
}
[ data-page-name = "profile" ] .field > .label { font-weight: 500; }


/* user card */
[ data-page-name = "profile" ] #user {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
[ data-page-name = "profile" ] #user > .profile-picture > img {
    aspect-ratio: 1/1;
    border-radius: 50%;
    height: 8vh;
    object-fit: cover;
}
[ data-page-name = "profile" ] #user > .text-and-ctas {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: fit-content;
}
[ data-page-name = "profile" ] #user > .text-and-ctas > .name {
    font-size: 1.5rem;
    font-weight: 500;
}
[ data-page-name = "profile" ] #user > .text-and-ctas > .ctas {
    display: flex;
    font-size: 0.85rem;
    gap: 1rem;
}
[ data-page-name = "profile" ] #user > .text-and-ctas > .ctas > * {
    background-color: var(--theme-button);
    border-radius: 0.25rem;
    color: var(--theme-color-10);
    padding: 0.33rem 1rem;
}

@media screen and (orientation: landscape) {
    [ data-page-name = "profile" ] > #content { display: inline-block; }
    [ data-page-name = "profile" ] > #content > * { float: left; }

    [ data-page-name = "profile" ] aside {
        height: fit-content;
        padding: 2rem;
        position: sticky;
        right: initial;
        top: 0;
        width: 50% !important;
    }

    [ data-page-name = "profile" ] main {
        gap: 3.75rem;
        margin-right: 5%;
        width: 45%;
    }

    /* user card */
    [ data-page-name = "profile" ] #user {
        align-items: center;
        flex-direction: row;
        gap: 2rem;
        height: 7.5rem;
        text-align: initial;
    }
    [ data-page-name = "profile" ] #user > * { height: 100%; }
    [ data-page-name = "profile" ] #user > .profile-picture { text-align: end; }
    [ data-page-name = "profile" ] #user > .profile-picture > img {
        aspect-ratio: 1/1;
        height: 100%;
    }
    [ data-page-name = "profile" ] #user > .text-and-ctas {
        align-items: initial;
        gap: initial;
        justify-content: space-between;
    }
}