[ data-page-name = "item_details" ] main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

[ data-page-name = "item_details" ] #gallery {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 45vh;
}
[ data-page-name = "item_details" ] #gallery > #preview {
    align-items: center;
    background: var(--theme-color-10);
    border-radius: var(--corner-rounding);
    display: flex;
    flex-grow: 1;
    justify-content: center;
}
[ data-page-name = "item_details" ] #gallery > #images {
    align-items: center;
    display: flex;
    gap: 1rem;
    height: 25%;
    overflow-x: auto;
    white-space: nowrap;
}
[ data-page-name = "item_details" ] #gallery > #images::-webkit-scrollbar { height: 0; }
[ data-page-name = "item_details" ] #gallery > #images > * {
    background: var(--theme-color-10);
    border-radius: var(--corner-rounding);
    flex-shrink: 0;
    height: 100%;
    padding: 1rem;
}
[ data-page-name = "item_details" ] #gallery > #preview img,
[ data-page-name = "item_details" ] #gallery > #images img { height: 100%; }


[ data-page-name = "item_details" ] #text,
[ data-page-name = "item_details" ] #text > .variety {
    display: flex;
    flex-direction: column;
}

[ data-page-name = "item_details" ] #text {
    background: var(--theme-color-10);
    border-radius: var(--corner-rounding);
    font-size: 0.85rem;
    gap: 2rem;
    padding: 2rem;
}
[ data-page-name = "item_details" ] #text > * {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

[ data-page-name = "item_details" ] #text > * > .title {
    font-weight: 500;
}

[ data-page-name = "item_details" ] #text > #brief > #name {
    display: block;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1.5rem !important;
}

[ data-page-name = "item_details" ] #text > .variety { gap: 1rem; }
[ data-page-name = "item_details" ] #text > .variety .options {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    white-space: nowrap;
}
[ data-page-name = "item_details" ] #text > .variety .options.colors > * {
    aspect-ratio: 1/1;
    border-radius: var(--corner-rounding);
    height: 1.5rem;
}
[ data-page-name = "item_details" ] #text > .variety .options.colors > .red { background: red; }
[ data-page-name = "item_details" ] #text > .variety .options.colors > .cream { background: beige; }
[ data-page-name = "item_details" ] #text > .variety .options.colors > .white { background: white; }
[ data-page-name = "item_details" ] #text > .variety .options.colors > .black { background: black; }
[ data-page-name = "item_details" ] #text > .variety .options.colors > .blue { background: blue; }
[ data-page-name = "item_details" ] #text > .variety .options.colors > .orange { background: orange; }
[ data-page-name = "item_details" ] #text > .variety .options.sizes { font-size: 0.85em; }
[ data-page-name = "item_details" ] #text > .variety .options.sizes > * {
    align-items: center;
    background: var(--theme-alt);
    border-radius: var(--corner-rounding);
    display: flex;
    height: 1.5rem;
    justify-content: center;
    padding-inline: 0.5rem;
}

[ data-page-name = "item_details" ] #text > #ctas {
    align-items: center;
    flex-direction: row;
    gap: 1rem;
}
[ data-page-name = "item_details" ] #text > #ctas > * {
    background: var(--theme-button);
    border-radius: var(--corner-rounding);
    color: var(--theme-color-10);
    flex: 1 1 0;
    padding: 0.33rem;
    text-align: center;
}

@media screen and (orientation: landscape) {
    [ data-page-name = "item_details" ] main {
        display: inline-block;
        height: 100%;
    }
    [ data-page-name = "item_details" ] main > * {
        float: left;
        width: calc((100% - 2rem) / 2);
    }

    [ data-page-name = "item_details" ] #gallery {
        height: 100%;
        margin-right: 2rem;
        max-height: 87.5vh;
        position: sticky;
        top: 0;
    }
    [ data-page-name = "item_details" ] #gallery > #images { height: 15%; }

    [ data-page-name = "item_details" ] #text { height: 100%; }
    [ data-page-name = "item_details" ] #text > * { max-width: 75%; }
}