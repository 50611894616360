[ data-page-name = "login" ] {
    background: url("../images/login_banner.png");
    background: url("../images/533b0aa7811a79497d6b43623f6109b7.jpg");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
[ data-page-name = "login" ]::before {
    background: linear-gradient(to bottom, transparent, rgba(180, 180, 180, 0), rgba(255, 0, 0, 0.45));
    background: rgba(255, 255, 255, 0.925);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--layer-underlay);
}

[ data-page-name = "login" ] main {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    height: 100%;
    justify-content: flex-end;
}

[ data-page-name = "login" ] main > #banner img { width: 87.5vw; }

[ data-page-name = "login" ] main > #form {
    background: var(--theme-alt);
    border-radius: var(--corner-rounding);
    padding: 1rem;
}
[ data-page-name = "login" ] main > #form > .alt {
    font-size: 0.75rem;
    margin-top: 1rem;
}
[ data-page-name = "login" ] main > #form > .alt a {
    color: blue;
    font-style: italic;
}

@media screen and (orientation: landscape) {
    [ data-page-name = "login" ] { background: url("../images/533b0aa7811a79497d6b43623f6109b7.jpg"); }
    [ data-page-name = "login" ]::before { background: rgba(255, 255, 255, 0.925); }

    [ data-page-name = "login" ] main {
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    [ data-page-name = "login" ] main > #banner img { height: 40vh; }
}