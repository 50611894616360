[ data-page-name = "payments" ] aside {
    background: var(--theme-alt);
    border-radius: var(--corner-rounding);
    padding: 1rem;
    height: fit-content;
    max-height: calc(85% - (2 * 10vh));
    min-height: calc(75% - (2 * 10vh));
    overflow-y: auto;
    padding: 2rem;
    position: fixed;
    right: 5vw;
    top: 20vh;
    width: calc(100% - 10vw);
}
[ data-page-name = "payments" ] aside > .close {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

[ data-page-name = "payments" ] main {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: relative;
}

/* section */
[ data-page-name = "payments" ] section {
    background: var(--theme-color-10);
    border-radius: var(--corner-rounding);
    padding: 1rem;
}
[ data-page-name = "payments" ] section > .head {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
[ data-page-name = "payments" ] section > .head > .title {
    font-size: 1.125rem;
    font-weight: 450;
}
[ data-page-name = "payments" ] section:first-child > .head > .title {
    font-size: 1.25rem;
    font-weight: 500;
}
[ data-page-name = "payments" ] section > .head > .cta {
    background: var(--theme-button);
    border-radius: 1rem;
    color: var(--theme-color-10);
    cursor: pointer;
    font-size: 0.75em;
    padding: 0.125rem 1rem;
}
[ data-page-name = "payments" ] section > :not(.head) { padding: 1.5rem 1rem; }
[ data-page-name = "payments" ] section > .content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}
[ data-page-name = "payments" ] section > .ctas {
    align-items: center;
    display: flex;
    gap: 1rem;
}
[ data-page-name = "payments" ] section > .ctas > * {
    background: var(--theme-neutral-dark);
    border-radius: var(--corner-rounding);
    color: #FFFFFF;
    flex: 1 0 0;
    font-size: 0.825rem;
    padding: 0.25rem;
    text-align: center;
}

[ data-page-name = "payments" ] .field {
    align-items: center;
    display: flex;
    gap: 1rem;
}
[ data-page-name = "payments" ] .field > .provider { width: 2.5rem; }
[ data-page-name = "payments" ] .field > .provider > img { width: 100%; }

@media screen and (orientation: landscape) {
    [ data-page-name = "payments" ] > #content { display: inline-block; }
    [ data-page-name = "payments" ] > #content > * { float: left; }

    [ data-page-name = "payments" ] aside {
        height: initial;
        max-height: initial;
        min-height: initial;
        padding: 2rem;
        position: sticky;
        right: initial;
        top: 0;
        width: 50% !important;
    }

    [ data-page-name = "payments" ] main {
        gap: 3.75rem;
        margin-right: 5%;
        width: 45%;
    }
}