[ data-page-name = "signup" ] {
    background: url("../images/login_banner.png");
    background: url("../images/533b0aa7811a79497d6b43623f6109b7.jpg");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
[ data-page-name = "signup" ]::before {
    background: linear-gradient(to bottom, transparent, rgba(180, 180, 180, 0), rgba(255, 0, 0, 0.45));
    background: rgba(255, 255, 255, 0.925);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: var(--layer-underlay);
}

[ data-page-name = "signup" ] main {
    background: var(--theme-alt);
    border-radius: var(--corner-rounding);
    padding: 1rem;
}
[ data-page-name = "signup" ] main .alt {
    font-size: 0.75rem;
    margin-top: 1rem;
}
[ data-page-name = "signup" ] main .alt a {
    color: blue;
    font-style: italic;
}

@media screen and (orientation: landscape) {
    [ data-page-name = "signup" ] {
        align-items: center;
        background: url("../images/533b0aa7811a79497d6b43623f6109b7.jpg");
        flex-direction: row !important;
    }
    [ data-page-name = "signup" ]::before { background: rgba(255, 255, 255, 0.925); }
    
    [ data-page-name = "signup" ] > * { flex: 1 1 0; }

    [ data-page-name = "signup" ] aside#slideshow {
        align-items: center;
        display: flex;
        overflow-x: auto;
    }
    [ data-page-name = "signup" ] aside#slideshow::-webkit-scrollbar { height: 0; }
    [ data-page-name = "signup" ] aside#slideshow > .slide {
        flex-shrink: 0;
        font-size: 3.75rem;
        font-weight: 750;
        padding: 5rem;
        position: relative;
        text-align: center;
        width: 100%;
    }
    [ data-page-name = "signup" ] aside#slideshow > .slide.style_a { color: var(--theme-color-30); }
    [ data-page-name = "signup" ] aside#slideshow > .slide.style_b {
        color: var(--theme-color-60);
        font-size: 5.75rem;
    }
    [ data-page-name = "signup" ] aside#slideshow > .slide.style_c {
        color: var(--theme-text-light);
        font-size: 4.5rem;
    }
    
    [ data-page-name = "signup" ] main {
        max-width: 44.5%;
    }
}


@media screen and (orientation: portrait) {
    [ data-page-name = "signup" ] aside { display: none; }
}