[ data-page-name = "add_item" ] > #content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
[ data-page-name = "add_item" ] > #content > * {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    font-size: 0.85rem;
    gap: 1rem;
}

[ data-page-name = "add_item" ] aside {
    background: var(--theme-background);
    padding-block: 1rem;
    position: sticky;
    top: -1rem;
}
[ data-page-name = "add_item" ] aside > .head,
[ data-page-name = "add_item" ] aside > .head > .view-buttons {
    align-items: center;
    display: flex;
    gap: 1rem;
}
[ data-page-name = "add_item" ] aside > .head { justify-content: space-between; }
[ data-page-name = "add_item" ] aside > .head > .view-buttons > * {
    cursor: pointer;
    padding: 0.33rem 0.5rem;
    position: relative;
}
[ data-page-name = "add_item" ] aside > .head > .view-buttons > .active::before {
    background: rgba(0, 0, 0, 0.25);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

[ data-page-name = "add_item" ] aside > .body {
    align-items: center;
    display: flex;
    justify-content: center;
}
[ data-page-name = "add_item" ] aside > .body.grid > .card { width: 50%; }
[ data-page-name = "add_item" ] aside > .body.list > .card { width: 100%; }

[ data-page-name = "add_item" ] main { order: 2 }

@media screen and (orientation: landscape) {
    [ data-page-name = "add_item" ] > #content { display: inline-block; }

    [ data-page-name = "add_item" ] > #content > * { float: left; }
    [ data-page-name = "add_item" ] > #content > :nth-child(odd) { margin-right: 5%; }

    [ data-page-name = "add_item" ] aside {
        background: initial;
        padding-block: initial;
        top: 0;
        width: 50%;
    }
    [ data-page-name = "add_item" ] aside > .body.grid > .card { width: 35%; }

    [ data-page-name = "add_item" ] main { width: 45%; }
    [ data-page-name = "add_item" ] main { width: 45%; }
}